.lootboxes-page {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #161616;
  color: white;
  min-height: 100vh;
  align-items: center;
}

.lootboxes-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  width: 100%;
}

.lootboxes-title {
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 22px;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.lootboxes-description {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 1.5;
  background: #1F1E1E;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 320px;
}

.lootboxes-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;
}

.lootbox-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 12px;
  padding: 0;
  transition: all 0.3s ease;
}

.lootbox-item:hover {
  transform: translateY(-5px);
}

.lootbox-image-container {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.lootbox-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.lootbox-item:hover .lootbox-image {
  transform: scale(1.05);
}

.lootbox-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 12px;
  padding: 3px 10px;
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 12px;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.lootbox-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.lootbox-name {
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 14px;
  text-align: center;
  color: white;
  margin-bottom: 2px;
}

.lootbox-rarity {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.lootbox-button {
  width: 100%;
  background: #CB3636;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 0;
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lootbox-button:hover {
  background: #d93d3d;
}

.lootbox-button.opening-soon {
  background: #CB3636;
}

.lootbox-button.disabled {
  background: #8B2525;
  opacity: 0.8;
}

/* Стили для задач - обновленная версия */
.tasks-section {
    margin-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}


.tasks-header {
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #fff;
}

.task-header {
  display: flex;
  align-items: center;
  position: relative;
}

.task-hint-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  cursor: help;
  transition: background-color 0.2s;
}

.task-hint-badge:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.hint-container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}

.hint-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.hint-badge:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.hint-popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  min-width: 200px;
  max-width: 300px;
  z-index: 10;
  margin-top: 8px;
  white-space: normal;
  text-align: left;
  line-height: 1.4;
  font-family: 'SF UI Display Light', sans-serif;
}

.hint-popup:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 10px;
  border-width: 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-item {
  background-color: rgba(30, 30, 30, 0.7);
  border-radius: 10px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.task-info {
  width: 70%;
  padding-right: 12px;
}

.task-name {
  font-family: 'SF UI Display Semibold', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 3px;
}

.task-description {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
}

.progress-container {
  position: relative;
}

.progress-bar {
  height: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 3px;
}

.progress-fill {
  height: 100%;
  background-color: #e74c3c;
  border-radius: 3px;
}

.progress-text {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 11px;
  color: #aaa;
  text-align: right;
}

.task-reward {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 12px;
}

.reward-text {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 5px;
}

.reward-lootbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
}

.reward-lootbox-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 3px;
}

.reward-lootbox-name {
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 11px;
  color: #fff;
  text-align: center;
}

.claim-button {
  font-family: 'SF UI Display Semibold', sans-serif;
  width: 100%;
  padding: 6px 0;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: rgba(231, 76, 60, 0.5);
  color: #ffffff;
}

.claim-button.disabled {
  opacity: 0.7;
}

/* Дополнительные стили для разных состояний задач */

/* Задача в процессе */
.task-item.in-progress {
  border-left: 3px solid #e74c3c; /* Красная полоса слева для задач в процессе */
}

/* Задача выполнена, но не заклеймлена */
.task-item.completed .progress-fill {
  background-color: #2ecc71; /* Зеленый цвет для выполненных задач */
}

.task-item.completed {
  border-left: 3px solid #2ecc71; /* Зеленая полоса слева */
}

/* Задача выполнена и заклеймлена */
.task-item.claimed {
  opacity: 0.7; /* Немного затемняем выполненную и заклеймленную задачу */
  border-left: 3px solid #95a5a6; /* Серая полоса слева */
}

.task-item.claimed .progress-fill {
  background-color: #95a5a6; /* Серый цвет для заклеймленных задач */
}

/* Стили для кнопок в разных состояниях */
.claim-button.active {
  background-color: #2ecc71; /* Зеленый цвет для активной кнопки Claim */
  color: white;
}

.claim-button.claimed {
  background-color: #95a5a6; /* Серый цвет для заклеймленных задач */
  color: white;
  cursor: default;
}

/* Добавляем иконку галочки для заклеймленных задач */
.claim-button.claimed:before {
  content: "✓ ";
}

.level-indicators {
  display: flex;
  justify-content: space-between;
  font-family: 'SF UI Display Light', sans-serif;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.lootbox-item:hover .lootbox-badge {
  animation: pulse 1.5s infinite;
}

.lootbox_badge {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 4px 8px;
}

.lootbox_badge svg {
  width: 20px;
  height: 20px;
  fill: #3390ec;
} 
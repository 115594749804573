body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #161616;
}




@font-face {
  font-family: 'SF UI Display Light';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Light'), url('/public/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
}


@font-face {
  font-family: 'SF UI Display Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Semibold'), url('/public/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
}


.page {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px;
  background-color: #161616;
  height: 100vh;
}

.header {
  width: 100%;
  height: 24px;
  padding-top: 40px;
  background-color: #161616;
  display: flex;
  justify-content: space-between;
}

.header_title {
  margin-left: 4px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: 'SF UI Display Semibold';
}

.header_title_second {
  margin-left: 4px;
  color: #AB9B9B;
  font-size: 16px;
  font-family: 'SF UI Display Semibold';
}

.header_logo {
  width: 24px;
  height: 24px;
}

.header_settings {
  width: 24px;
  height: 24px;
}

.header_airdrop {
  margin-right: 8px;
  color: #2E2C27;
  padding: 6px 12px 6px 12px;
  border-radius: 24px;
  background-color: #FFCC00;
  font-family: 'SF UI Display Semibold';
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header_airdrop:hover {
  background-color: #FFA600;
}

.df {
  display: flex;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}

.tac {
  text-align: center;
}

.header_left {
  display: flex;
  padding-left: 8px;
}

.header_right {
  display: flex;
  padding-right: 8px;
  gap: 5px;
}

.wallet_info {
  padding-top: 20px;
  padding-left: 12px;
}

.wallet_title_text {
  color: #ffffff;
  font-size: 14px;
  font-family: 'SF UI Display Semibold';
  cursor: pointer;
  background-color: #1F1E1E;
  border-radius: 8px;
  padding: 8px 8px 8px 8px
}

.tap_to_copy {
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 4px;
  color: #32BA82;
  font-family: 'SF UI Display Semibold';
  font-size: 12px;
}

.wallet_title>img {
  vertical-align: middle
}

.wallet_title {
  display: flex;
  align-items: center;
}

/*  */

.wallet_balance {
  margin-top: 8px;
}

.wallet_balance_title {
  font-size: 40px;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
  display: flex;
  align-items: center;
}

.wallet_balance img {
  margin-left: 10px;
}

.wallet_secondary {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: auto;
  cursor: pointer;
  transition: all 0.2s ease;
}

.stars_badge {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  padding: 4px 12px;
  font-size: 14px;
  color: #FFD700;
  font-family: 'SF UI Display Semibold';
  transition: all 0.2s ease;
}

.stars_badge:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.stars_badge:active {
  transform: translateY(0px);
}

.stars_badge svg {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  fill: #FFD700;
}

/*  */

.menu {
  display: flex;
  gap: 8px;
  margin-top: 36px;
  overflow-x: auto;
  white-space: nowrap;
}

.menu_item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 10px;
  background-color: #1F1E1E;
  color: #ffffff;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.menu .active {
  background-color: #3D3D3D;
}

.menu_item_icon {}

.menu_item_title {
  margin-left: 6px;
  font-size: 12px;
  font-family: 'SF UI Display Semibold';
  padding-top: 3px;
}

.menu_content {
  margin-top: 8px;
  background-color: #1F1E1E;
  border-radius: 16px;
  padding: 12px;
}

.menu_content_title {
  color: #ffffff;
  font-size: 16px;
  font-family: 'SF UI Display Semibold';
}

.swap_btn {
  margin-top: 16px;
  width: 100%;
  border-radius: 26px;
  color: #ffffff;
  overflow: auto;
  background-color: #CB3636;
  margin-left: auto;
  margin-right: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.swap_btn:hover {
  background-color: #FF3D3D;
}

.swap_btn_icon {}

.swap_btn_text {
  margin-left: 6px;
  font-family: 'SF UI Display Semibold';
}

.regularText {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  font-family: 'SF UI Display Light';
}


.mtxs {
  margin-top: 4px;
}

.mtd {
  margin-top: 8px;
}

.mld {
  margin-left: 8px;
}


.mtl {
  margin-top: 24px !important;
}

.mtxl {
  margin-top: 36px !important;
}

.mtxxl {
  margin-top: 48px;
}


.airdrop_info {
  margin-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
}

.jc {
  justify-content: center;
}

.airdrop_text {
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 16px;
  margin-left: 4px;
}

.normal_text {
  color: #ffffff;
  font-family: 'SF UI Display Light';
  font-size: 16px;
}

.airdrop_list li {
  color: #ffffff;
  font-size: 14px;
  padding-left: 20px;
  line-height: 1.9;
  font-family: 'SF UI Display Light';
}

.airdrop_list {
  margin-left: 0 !important;
  padding-left: 16px !important;
}

.semibold {
  font-family: 'SF UI Display Semibold';
}

.text_white {
  color: #ffffff;
}



.ref_btn {
  margin-top: 14px;
  width: 100%;
  border-radius: 26px;
  color: #ffffff;
  overflow: auto;
  background-color: #1F1E1E;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.ref_btn:hover {
  background-color: #0E0E11;
}

.ref_btn_text {
  margin-left: 6px;
  color: #70BAF5;
  font-size: 14px;
  padding-top: 4px;
  font-family: 'SF UI Display Semibold';
}

.mt12 {
  margin-top: 12px !important;

}

.mt0 {
  margin-top: 0px !important;

}

.swap_up_part {}

.swap_coin_type {
  background-color: #303030;
  display: flex;
  border-radius: 40px;
  padding: 8px;
  align-items: center;
  cursor: pointer;
}

.swap_coin_type_title {
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 14px;
  margin-left: 8px;
  text-transform: uppercase;
}

.swap_coin_type_icon {
  margin-left: 4px;
  border-radius: 50%;
}

.swap_right_up_part {
  color: #AB9B9B;
  font-size: 14px;
  font-family: 'SF UI Display Light';
  display: flex;
  align-items: center;
  cursor: pointer;
}

.swap_right_up_part img {
  margin-right: 4px;
}

.swap_right_down_part {
  color: #AB9B9B;
  font-size: 14px;
  font-family: 'SF UI Display Light';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}



.percentage-difference {
  margin-left: 4px;
  font-family: 'SF UI Display Light';
}


.swap_input {
  border: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  color: #ffffff !important;
  font-family: 'SF UI Display Semibold';
  flex: 1;
  min-width: 0;
  margin-right: 10px;
}

.swap_up_part_first_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swap_up_part_second_row {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swap_middle_part {
  display: flex;
  align-items: center;
}

.line {
  background-color: #403F3F;
  height: 1px;
  width: 100%;
}

.swapCoinBtn {
  cursor: pointer;
}

.settings_crisper {
  border-radius: 16px;
  background-color: #1F1E1E;
  padding: 16px 12px 16px 12px;
  color: #ffffff;
  margin-top: 24px;
}

.settings_row {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.settings_row_title {
  font-size: 14px;
  font-family: 'SF UI Display Light';
  align-items: center;
  display: flex;
}

.settings_row_value {
  font-size: 14px;
  font-family: 'SF UI Display Semibold';
  display: flex;
  align-items: center;
}

.settings_line {
  background-color: #403F3F;
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.caret_right {
  width: 24px;
  vertical-align: middle;
}

.caret_down {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 24px;
  vertical-align: middle;
}

.caret_left {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 12px;
  height: 8px;
  vertical-align: middle;
}

.settings_crisper_header {
  color: #AB9B9B;
  font-size: 14px;
  font-family: 'SF UI Display Light';
  margin-top: 40px;
}

.mt4 {
  margin-top: 4px !important;
}

.settings_ref_link {
  border-radius: 12px;
  background-color: #171515;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #AB9B9B;
  padding: 12px 16px 12px 16px;
  font-family: 'SF UI Display Light';
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
}

.settings_share_btn {
  margin-top: 16px;
  width: 100%;
  border-radius: 26px;
  color: #ffffff;
  background-color: #1769E2;
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  cursor: pointer;
  font-family: 'SF UI Display Semibold';
  font-size: 14px;
  display: block;
}

.settings_share_btn:hover {
  background-color: #5398FF;
}

.header_back {
  color: #AB9B9B;
  font-size: 12px;
  font-family: 'SF UI Display Semibold';
  display: flex;
  align-items: center;
  margin-top: 4px;
}

a {
  text-decoration: none !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.onboarding_crisper {
  background-color: #161616;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
}

.fs_crisper {
  background-color: #161616;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
}

.onboarding_smile {
  margin-top: 86px;
}


.onboarding_steps {
  font-family: 'SF UI Display Semibold';
  font-size: 13px;
  color: #3A3B3D;
  margin-top: 44px;
  padding-left: 16px;
  padding-right: 16px;
}

.onboarding_title {
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
  font-size: 28px;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.onboarding_message {
  font-family: 'SF UI Display Light';
  color: #ffffff;
  font-size: 16px;
  margin-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.default_next_btn {
  background-color: #3E88F7;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 16px;
  margin-top: 32px;
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.pdside16 {
  padding-left: 16px;
  padding-right: 16px;
}

.fs_modal {
  background-color: #161616;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}

.seed_popup_title {
  font-size: 28px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  margin-top: 18px;
  text-align: center;
}

.seed_popup_text {
  font-size: 18px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
  margin-top: 10px;
  line-height: 1.55;
}

.close_btn {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 50%;
  background-color: #1F1E1E;
  align-items: center;
  display: flex;
  justify-content: center;
}


.close_btn img {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: -2px;
  width: 24px;
}

.seed_words_list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  font-family: 'SF UI Display Light';
  font-size: 16px;
  column-gap: 100px;
  padding-inline-start: 20px;
}

.seed_words_list li {
  color: #ffffff;
  padding-left: 8px;
  padding-top: 12px;
}

.seed_words_list li::marker {
  color: #AB9B9B;
}



.seed_words_crisper {
  display: flex;
  justify-content: center;
}

.unclickable {
  pointer-events: none;
  opacity: 0.5;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.swap_coin_type_caret {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.pl12 {
  padding-left: 12px;
}


.modal {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  will-change: contents;
}

.bottom_stuck_modal {
  background-color: #161616;
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  z-index: 999;
}

.bottom_stuck_modal_content {
  position: relative;
  overflow: hidden;
  text-align: left;
}


.popup_title {
  font-size: 28px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  margin-top: 18px;
  text-align: center;
}

.popup_text {
  font-size: 18px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
  margin-top: 10px;
  line-height: 1.55;
  text-align: center;
}


.assets_crisper {
  overflow-y: auto;
  max-height: 80vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.asset_item {
  background-color: #303030;
  display: flex;
  border-radius: 40px;
  padding: 8px;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
}

.asset_item_image {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.asset_short_title {
  font-size: 20px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.asset_title {
  font-size: 15px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
  margin-top: 4px;
}

.asset_desc {
  text-align: left;
  margin-left: 12px;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal_container {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;
}

.modal_container.visible {
  opacity: 1;
  pointer-events: auto;
}

.modal_container .bottom_stuck_modal {
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.modal_container.visible .bottom_stuck_modal {
  transform: translateY(0);
}

.modal_overlay_half {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.modal_container.visible .modal_overlay_half {
  opacity: 1;
}

.asset_balance {
  font-size: 20px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.asset_usd_balance {
  font-size: 15px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
  margin-top: 4px;
}

.asset_balance_desc {
  text-align: right;
  margin-left: auto;
  margin-right: 12px;
}

.asset_search_input {
  border: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  color: #ffffff;
  font-family: 'SF UI Display Light';
  background-color: #303030;
  border-radius: 40px;
  padding: 10px 10px 10px 30px;
  width: 100%;
  margin-top: 12px;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* .Toastify__toast-container {
    width: 200px!important;
} */


.settings_coin_balances {
  margin-top: 8px;
}


.asset_item_plain {
  background-color: #303030;
  display: flex;
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid #403F3F;
}

.asset_item_plain:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.asset_item_plain:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.not_found_message {
  text-align: center;
  margin-top: 20px;
}

.imported_badge {
  color: #ec502c;
  font-size: 12px;
  font-family: 'SF UI Display Light';

  border: 1px solid #ec502c;
  border-radius: 30px;
  padding: 2px 5px 2px 5px;
  margin-left: 6px;
  margin-top: 2px;
  display: block;
  float: right;
}

.active_asset {
  background-color: #252222 !important;
}


.default_input {
  border: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  font-size: 20px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.address_input:focus-visible {
  border-bottom: 2px solid #3E88F7;
}

.input_valid {
  border-bottom: 2px solid #39cc83 !important;
}

.address_input {
  margin-top: 24px;
  width: 100%;
}

.amount_input {
  margin-top: 24px;
  font-size: 40px;
  max-width: 230px;
}

.amount_input_crisper {}

.amount_input_suffix {
  color: #8994a3;
  font-size: 28px;
  font-family: 'SF UI Display Semibold';

}

.amount_input_content {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  font-size: 40px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.amount_input_error_message {
  color: #f53c36;
  font-size: 15px;
  font-family: 'SF UI Display Light';
  padding-left: 2px;
}

.error>.amount_input_suffix,
.error>.amount_input {
  color: #f53c36;
}

.asset_logo_crisper {
  width: 64px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
}

.asset_logo_crisper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.confirm_send_title {
  color: #8994a3;
  font-size: 15px;
  text-align: center;
  font-family: 'SF UI Display Light';
  margin-top: 24px;
}

.confirm_send_subtitle {
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}


.plain_badge {
  background-color: #303030;
  display: flex;
  padding: 14px;
  align-items: center;
  border-bottom: 1px solid #403F3F;
  justify-content: space-between;
}

.plain_badge:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 48px;
}

.plain_badge:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.plain_badge_title {
  font-size: 15px;
  font-family: 'SF UI Display Semibold';
  color: #8994a3;
}

.plain_badge_value {
  font-size: 15px;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
}

.from_balance_crisper {
  display: flex;
  align-items: center;
  margin-top: 15vh;
}

.from_balance_asset_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.from_balance_asset_desc {
  margin-left: 12px;
  line-height: 1.3;
}

.from_balance_asset_title {
  font-size: 16px;
  font-family: 'SF UI Display Light';
  color: #8994a3;
}

.from_balance_asset_value {
  font-size: 18px;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
}


.recent_address_crisper {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.recent_address_asset_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.recent_address_asset_desc {
  margin-left: 12px;
  line-height: 1.3;
}

.recent_address_asset_title {
  font-size: 18px;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
}

.recent_address_asset_value {
  font-size: 16px;
  font-family: 'SF UI Display Light';
  color: #8994a3;
}

.recent_addresses_title {
  margin-top: 24px;
  font-family: 'SF UI Display Light';
  color: #8994a3;
}

.receiver_address_input_container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.paste_btn {
  position: absolute;
  right: 5px;
  top: 1px;
  padding-left: 5px;
  margin-top: 24px;
  color: #3E88F7;
  font-family: 'SF UI Display Semibold';
  font-size: 18px;
}

.absolute_loading {
  position: absolute;
  top: 0;
  z-index: 999;
}


.swap_toolbar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.swap_refresh {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.swap_refresh svg {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.swap_settings svg {
  color: #FFFFFF;
  fill: #FFFFFF;
}


.swap_settings {
  margin-left: 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal_overlay_half {
  background-color: rgb(48, 48, 48, 0.9);
  opacity: 0.9;
  height: 60%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  z-index: 999;

}

.half_stuck_modal {
  height: 50% !important;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  min-height: 410px;
  will-change: transform;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.modal_container {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  z-index: 999;
  pointer-events: none;
}

/*
.half_stuck_modal::before {
  background-color: #403F3F;
  border-radius: .125rem;
  content: "";
  height: .25rem;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: .5rem;
  margin-left: -2.625rem;
  width: 5.25rem;
}

*/


.visible {
  opacity: 1 !important;
}

.invisible {
  opacity: 0 !important;
  height: 0 !important;
}


.h4 {
  font-size: 20px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}


.text_left {
  text-align: left !important;
}

.text_xs {
  font-size: 14px;
}

.slippage_badge {
  width: calc(30% - .5em);
  margin-left: 1em;
  border-radius: 30px;
  border: 1px solid #AB9B9B;
  padding: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'SF UI Display Light';
  font-size: 14px;
  cursor: pointer;
}

.slippage_badge:first-child {
  margin-left: 0 !important;
}

.badges {
  display: flex;
}

.slippage_badge_active {
  border: 1px solid #CB3636 !important;
  color: #CB3636 !important;
}

.toolbar_header {
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
  font-size: 22px;
}

.custom_slippage_input {
  margin-top: 20px;
  font-size: 14px;
  background-color: #1F1E1E;
  border-radius: 30px;
  padding: 20px;
  border: none;
  border-color: transparent;
  outline: none;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  text-align: center;
}

.custom_slippage_input_active {
  border: 1px solid #CB3636 !important;
  color: #CB3636 !important;
}

.save_button {
  width: 100%;
  height: 48px;
  background: #CB3636;
  border-radius: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 24px;
  font-family: 'SF UI Display Semibold';
}



@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal_container.visible {
  pointer-events: auto;
}

.modal_container.visible .half_stuck_modal {
  transform: translateY(0);
}

.modal_container.visible .half_stuck_modal {
  transform: translate3d(0, 0, 0);
}

.half_stuck_modal {
  transform: translate3d(0, 100%, 0);
  animation: slideUp 0.3s ease-out forwards;
  /* остальные стили */
}


.modal_container.visible {
  animation: fadeIn 0.3s ease-out forwards;
}

.modal_container.visible .bottom_stuck_modal {
  animation: slideUp 0.3s ease-out forwards;
}

.modal_overlay_half {
  animation: fadeIn 0.3s ease-out forwards;
}


/* Стили для скроллируемого модального окна */
.modal_scrollable.bottom_stuck_modal {
  display: flex;
  flex-direction: column;
}

.modal_scrollable.half_stuck_modal {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal_scrollable .bottom_stuck_modal_content {
  flex-grow: 1;
  overflow-y: auto;
}

/* Скрываем скроллбар для WebKit браузеров (Chrome, Safari, новые версии Edge) */
.modal_scrollable.half_stuck_modal::-webkit-scrollbar,
.modal_scrollable .bottom_stuck_modal_content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Скрываем скроллбар для Firefox */
.modal_scrollable.half_stuck_modal,
.modal_scrollable .bottom_stuck_modal_content {
  scrollbar-width: none;
}

/* Скрываем скроллбар для IE и Edge */
.modal_scrollable.half_stuck_modal,
.modal_scrollable .bottom_stuck_modal_content {
  -ms-overflow-style: none;
}


@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.rotate {
  -webkit-animation: rotation 1.6s infinite linear;
  animation: rotation 1.6s infinite linear;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.btn_red {
  background-color: #CB3636 !important;
}


.loading_crisper {
  padding: 20px;
  border-radius: 10px;
  color: white;
  /* Убедитесь, что текст внутри виден */
  mix-blend-mode: screen;
}

.pl {
  position: relative;
}

.unclickable_menu_content {
  pointer-events: none;
  background-color: rgb(31, 30, 30, 0.5) !important;
}

.loading_crisper_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  mix-blend-mode: normal;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal_container {
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 50px;
  /* Дополнительный отступ снизу */
}



/* Меню */
.wallet-menu-container {
  position: relative;
  display: inline-block;
}

.caret_down {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.wallet_dropdown_menu {
  position: absolute;
  background-color: #1F1E1E;
  border-radius: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 180px;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top right;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  top: -16px;
  left: 12px;
  padding: 8px;
  will-change: opacity, transform, visibility;
}

@media screen and (max-width: 378px) {
  .wallet_dropdown_menu {
    left: calc((100vw - 370px));
  }
}

.wallet_dropdown_menu.open {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.wallet_dropdown_menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.wallet_dropdown_menu li {
  cursor: pointer;
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  padding: 8px;
}

.wallet_dropdown_menu li img {
  width: 24px;
  height: 24px;
}

.wallet_dropdown_menu li span {
  margin-left: 8px;
  font-size: 14px;
  font-family: 'SF UI Display Semibold';
  color: #ffffff;
}

.wallet_dropdown_menu.open li {
  opacity: 1;
  transform: translateY(0);
}

.wallet_dropdown_menu li:hover {
  background-color: #161616;
}

/* Добавляем задержку для каждого пункта меню */
.wallet_dropdown_menu li:nth-child(1) {
  transition-delay: 0.1s;
}

.wallet_dropdown_menu li:nth-child(2) {
  transition-delay: 0.2s;
}

/* .wallet_dropdown_menu li:nth-child(3) { transition-delay: 0.3s; } */
/* Добавьте больше задержек, если у вас больше пунктов меню */

.blue_text {
  color: #1769E2 !important;
}


.mev-protection {
  display: flex;
  width: 100%;
}

.mev-protection {
  display: flex;
  width: 100%;
  background-color: #1F1E1E;
  /* Темный фон для родительского элемента */
  border-radius: 16px;
  /* Закругление для родительского элемента */
  padding: 4px;
  /* Отступ для внутренних элементов */
}

.mev-badge {
  width: 50%;
  border: 2px solid transparent;
  /* Прозрачная граница по умолчанию */
  padding: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 14px;
  cursor: pointer;
  border-radius: 12px;
  /* Закругление для внутренних элементов */
}

.mev-badge-active {
  background-color: rgba(203, 54, 54, 0.05);
  /* Очень прозрачный красный фон */
  border-color: #CB3636;
  color: #CB3636;
}

.percentage-difference.positive {
  color: #32CD32;
  /* Зеленый цвет для положительной разницы */
}

.percentage-difference.negative {
  color: #FF4136;
  /* Красный цвет для отрицательной разницы */
}

@media screen and (max-width: 350px) {
  .swap_input {
    max-width: 60%;
  }

  .percentage-difference {
    display: none;
  }
}

.mev-badge.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.asset_item_dark {
  background-color: #1F1E1E !important;
}

.burn_section {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 8px;
}

.burn_fire_crisper {
  height: 200px;
  position: relative;
}

.burn_progress {
  border-radius: 40px;
  background-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 12px 21px 12px 21px;
  position: absolute;
  top: 50%;
  /* Перемещение вниз на 50% высоты родителя */
  left: 50%;
  /* Перемещение вправо на 50% ширины родителя */
  transform: translate(-50%, -50%);
  /* Сдвиг элемента на половину его ширины и высоты */
  z-index: 5;
  backdrop-filter: blur(8px);
}

.progress_fill {
  backdrop-filter: blur(16px);
  border-top-left-radius: 40px;
  /* Чтобы заполнение имело такие же скругления */
  border-bottom-left-radius: 40px;
  /* Чтобы заполнение имело такие же скругления */
  background-color: rgba(255, 255, 255, 0.32);
  /* Цвет заполнения (можно изменить) */
  height: 100%;
  /* Высота заполнения равна высоте контейнера */
  position: absolute;
  /* Позиционируем заполнение абсолютно */
  top: 0;
  /* Заполнение начинается с верхней части контейнера */
  left: 0;
  /* Заполнение начинается с левой части контейнера */
}

.progress_fill_right_rounded {
  border-top-right-radius: 40px;
  /* Чтобы заполнение имело такие же скругления */
  border-bottom-right-radius: 40px;
  /* Чтобы заполнение имело такие же скругления */
}

.burn_progress_level_label {
  font-family: 'SF UI Display Light';
  color: #ffffff;
  font-size: 14px;
  z-index: 1;
}

.burn_action_crisper {
  background-color: #1F1E1E;
  padding: 16px 12px 16px 12px;
  border-radius: 40px;
  margin-left: 8px;
  margin-right: 8px;
  z-index: 5;
  position: relative;
  margin-top: -20px;
}

.rounded_input {
  border: none;
  border-color: transparent;
  outline: none;
  background-color: #161616;
  font-size: 20px;
  color: #ffffff !important;
  font-family: 'SF UI Display Semibold';
  flex: 1;
  min-width: 0;
  border-radius: 40px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.swap_coin_type_fs {
  background-color: #161616;
  display: flex;
  border-radius: 40px;
  padding: 12px;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
}

.swap_coin_type_fs .swap_coin_type_title {
  font-size: 16px !important;
}

.swap_coin_type_fs .swap_coin_type_caret {
  margin-left: auto;
}

.burn_button {
  border-radius: 40px;
  padding: 14px;
}

.plain_rounded_list_crisper {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 16px;
  background-color: #1F1E1E;
}

.plain_rounded_list_header {
  color: #FFFFFF;
  font-size: 20px;
  font-family: 'SF UI Display Semibold';
  padding: 16px 12px 16px 12px;
}

.plain_rounded_list_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #161616;
  padding: 16px 12px 16px 12px;
}

.plain_rounded_list_item_left {}

.plain_rounded_list_item_title {
  font-size: 16px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.plain_rounded_list_item_right {
  font-size: 16px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  width: 32px;
  text-align: center;
}

.plain_rounded_list_item_subtitle {
  font-size: 12px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}

.settings_coin_balances .plain_rounded_list_header {
  background-color: #1F1E1E;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom: 1px solid #161616;
}

.settings_coin_balances .asset_item_plain {
  border-color: #161616 !important;
}


.rounded_crisper_dark {
  background-color: #1F1E1E;
  padding: 16px 12px 16px 12px;
  border-radius: 40px;
  z-index: 5;
}

.padding16 {
  padding: 16px;
}

.semibold_white_text {
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.fs20 {
  font-size: 20px !important;
}

.fs18 {
  font-size: 16px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs14 {
  font-size: 14px !important;
}

.semitransparent {
  opacity: 0.5;
}

.placeholder_color {
  color: #a9a9a9
}

.rounded_input::placeholder {
  font-weight: 600 !important;
  color: #a9a9a9 !important;
}

.group_select_popup .asset_item_plain {
  padding-top: 12px;
  padding-bottom: 12px;
}

.burn_section .burn_action_crisper {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}


.loading-container {
  width: 300px;
  /* Ширина контейнера по умолчанию */
  height: 20px;
  /* Высота контейнера */
  position: relative;
  /* Для позиционирования внутреннего элемента */
  overflow: hidden;
  /* Скрыть переполнение */
  background-color: #1F1E1E;
  /* Цвет фона контейнера */
  border-radius: 6px;
  /* Закругленные углы */
}

.loading-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  /* Начальная позиция за пределами контейнера */
  height: 100%;
  width: 100%;
  background: linear-gradient(270deg, #303030, #1F1E1E);
  /* Градиент для эффекта заполнения */
  animation: loading-animation 3s ease infinite;
  /* Анимация загрузки */
}

@keyframes loading-animation {
  0% {
    left: -100%;
    /* Начальная позиция */
  }

  100% {
    left: 100%;
    /* Конечная позиция */
  }
}


.default_grey_text {
  font-size: 15px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}

.transfer_amount_slider {
  max-width: 250px !important;
  width: 180px !important;
  margin-right: 10px;
}

.transfer_amount_input {
  border: none;
  border-color: transparent;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  color: #ffffff !important;
  font-family: 'SF UI Display Semibold';
  flex: 1;
  min-width: 0;
  width: 180px !important;
  text-align: center;
}

@media only screen and (max-width: 430px) {
  .transfer_amount_slider {
    width: 130px !important;
    margin-right: 0 !important;
  }

  .transfer_amount_input {
    width: 130px !important;
  }
}

.blue_style {
  color: #ffffff !important;
  background-color: #3E88F7 !important;
}

.menu_content_ext_wallets .asset_item_dark:last-child {
  border-bottom: none !important;
}

.burn_action_crisper .asset_item_dark:last-child {
  border-bottom: none !important;
}

.wallet-mode-badges {
  display: flex;
  width: 100%;
  background-color: #161616;
  border-radius: 16px;
  padding: 4px;
  box-sizing: border-box;
}

.wallet-mode-badge {
  width: 50%;
  border: 2px solid transparent;
  padding: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 14px;
  cursor: pointer;
  border-radius: 12px;
}

.wallet-mode-badge-active {
  background-color: rgb(22, 22, 22, 0.05);
  /* Очень прозрачный красный фон */
  border-color: #CB3636;
  color: #CB3636;
}


.wallet-mode-badges-light {
  display: flex;
  width: 100%;
  background-color: #1F1E1E;
  border-radius: 16px;
  padding: 4px;
  box-sizing: border-box;
}

.wallet-mode-badge-light {
  width: 50%;
  border: 2px solid transparent;
  padding: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 14px;
  cursor: pointer;
  border-radius: 12px;
}

.wallet-mode-badge-light-active {
  background-color: rgb(31, 30, 30, 0.05);
  /* Очень прозрачный красный фон */
  border-color: #CB3636;
  color: #CB3636;
}


.transfer_amount_slider .MuiSlider-valueLabel {
  display: none;
}

.transfer_amount_slider input[type="range"]:not([value="0"])+.MuiSlider-valueLabel {
  display: block;
}



.progress-group {
  padding: 16px 0;
}

.progress-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.progress-bar {
  background-color: #171515;
  border-radius: 5px;
  height: 10px;
  overflow: visible;
  /* Позволяет тени быть видимой */
  position: relative;
  /* Для абсолютного позиционирования fill */
}

.progress-fill {
  background-color: rgb(203, 54, 54);
  height: 100%;
  transition: width 0.5s;
  box-shadow: 0 0 10px rgba(203, 54, 54, 0.8);
  /* Тень со всех сторон */
  border-radius: 5px;
  /* Закругление краёв для эффекта свечения */
  position: absolute;
  /* Абсолютное позиционирование */
}

.progress-levels {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
}

.tasks-link {
  color: #0f0;
  text-decoration: none;
  float: right;
}


.regularLightText {
  font-size: 15px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}


.win-effect {
  animation: brightLights 1.5s infinite alternate;
  /* Анимация ярких огней */
}

@keyframes brightLights {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5), 0 0 20px rgba(255, 255, 0, 0.3);
  }

  100% {
    box-shadow: 0 0 20px rgba(255, 255, 0, 1), 0 0 40px rgba(255, 255, 0, 0.5);
  }
}


.spin_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spin-toolbar-btn {
  padding: 18px;
  border-radius: 40px;
}

.spinning_utility_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spinning_combo_info {}

.spinning_combo_info_item {
  display: flex;
  margin-top: 2px;
  align-items: center;
}

.spinning_combo_info_images {
  display: flex;
}

.spinning_combo_info_images img {
  width: 24px;
}

.spinning_bet_menu {
  width: 50%;
  padding-left: 20px;
  padding-top: 20px;
}

.spinning_combo_info_text {
  font-size: 16px;
  margin-left: 2px;
}

.btn_black {
  background-color: #161616 !important;
}

:root {
  --primary-blue: #00BFFF;
  /* Основной синий цвет */
  --secondary-blue: rgba(0, 191, 255, 0.3);
  /* Вторичный синий цвет */
  --third-blue: rgba(0, 191, 255, 0.5);
}

.spin-balance {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: var(--primary-blue);
  /* Используем переменную */
  font-size: 18px;
  font-weight: 600;
  font-family: 'SF UI Display Semibold';
}

.spin_button {
  width: 140px;
  height: 45px;
  margin: 10px auto 20px;
  background: linear-gradient(145deg, #3a1818, #2a1212);
  border: 1px solid #ff4d4d33;
  border-radius: 25px;
  color: #ff4d4d;
  /* Этот цвет мож  но оставить без изменений или заменить на переменную */
  font-size: 20px;
  font-weight: 600;
  display: block;
  transition: all 0.3s ease;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  font-family: 'SF UI Display Semibold';
}

/* Эффект при нажатии на кнопку */
.spin_button:active {
  transform: scale(0.95);
  /* Уменьшаем размер кнопки при нажатии */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  /* Уменьшаем тень для эффекта нажатия */
}

/* Эффект при фокусе на кнопке */
.spin_button:focus {
  outline: none;
  /* Убираем стандартный контур */
  box-shadow: 0 0 0 3px rgba(255, 77, 77, 0.5);
  /* Добавляем эффект фокуса */
}

.spin_slot_gh {
  border: 3px solid var(--primary-blue);
  /* Используем переменную */
  box-shadow: 0 0 20px var(--primary-blue);
  /* Используем переменную */
  border-radius: 10px;
  /* Убраны кавычки */
  overflow: hidden;
  /* Убраны кавычки */
}

.spin_slot {
  border: 3px solid var(--secondary-blue);
  box-shadow: 0 0 10px var(--third-blue);
  border-radius: 10px;
  overflow: hidden;
}

.spin_header {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  font-family: 'SF UI Display Semibold';
  animation: neonPulse 3s infinite;
  letter-spacing: 2px;
  padding-left: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 5px var(--primary-blue), 0 0 10px var(--primary-blue), 0 0 20px var(--primary-blue), 0 0 40px var(--primary-blue), 0 0 80px var(--primary-blue);
}

.spin_crisper {
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 20px var(--primary-blue);
  border-radius: 10px;
  padding: 15px;
}


.spin_toolbar .swap_coin_type_fs {
  width: 40%;
}

.spin_toolbar_icon {
  filter: brightness(0) invert(1);
  cursor: pointer;

}

.deposit_toolbar_with_text {
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
  font-size: 16px;
  align-items: center;
}

.deposit_toolbar_with_text>div>img {
  padding-left: 4px;
  margin-top: 4px;
}

.spin_toolbar_icon:nth-child(1) {
  margin-right: 10px;
}

@media screen and (max-width: 360px) {
  .spinning_combo_info {
    display: none;
  }

  .spinning_bet_menu {
    width: 80%;
  }
}


.spinning_bet_menu_display_bet_amount {
  text-align: center;
  font-size: 18px;
  margin-bottom: 12px;
}



.swap-details {
  padding-top: 12px;
  font-family: 'SF UI Display Semibold';
}

.swap-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 0;
  color: #ffffff;
  font-size: 18px;
}

.swap-details-header img {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
  transform: rotate(90deg);
}

.swap-details-header img.caret-expanded {
  transform: rotate(-90deg);
}

.swap-details-content {
  margin-top: 12px;
}

.swap-detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  color: #AB9B9B;
  font-size: 14px;
}

.swap-detail-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info-icon {
  width: 14px;
  height: 14px;
  opacity: 0.6;
  cursor: help;
}

.swap-detail-value {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 4px;
}

.usd-value {
  color: #AB9B9B;
  font-size: 13px;
}

.swap-history-item {
  background: #1F1E1E;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-family: 'SF UI Display Semibold';
  position: relative;
  /* Добавлено для позиционирования статуса */
}

.swap-history-item__status {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 24px;
  height: 24px;
  background: #161616;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1F1E1E;
  z-index: 1;
}

.swap-history-item__status.loading {
  color: #AB9B9B;
}

.swap-history-item__amount--pending {
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}

.swap-history-item__loading {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Настраиваем размер Lottie анимации */
.swap-history-item__loading>div {
  width: 24px !important;
  height: 24px !important;
}

.swap-history-item__status.success {
  color: #1DB954;
  /* Зеленый цвет для успешных свопов */
}

.swap-history-item__status.failed {
  color: #FF3B30;
  /* Красный цвет для неуспешных свопов */
}

.swap-history-item__left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.swap-history-item__icon {
  width: 40px;
  height: 40px;
  background: #161616;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #AB9B9B;
}

.swap-history-item__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.swap-history-item__title {
  font-size: 16px;
  color: #ffffff;
  font-family: 'SF UI Display Semibold';
}

.swap-history-item__address {
  font-size: 14px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}

.swap-history-item__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.swap-history-item__amounts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}

.swap-history-item__amount {
  font-size: 15px;
  font-family: 'SF UI Display Semibold';
}

.swap-history-item__amount--positive {
  color: #1DB954;
  /* Вернули зеленый цвет как на фото */
}

.swap-history-item__amount--negative {
  color: #ffffff;
}

.swap-history-item__time {
  font-size: 14px;
  color: #AB9B9B;
  font-family: 'SF UI Display Light';
}

@keyframes gentle-pulse {
  0% {
    opacity: 0.5;
    transform: scale(0.98);
  }

  50% {
    opacity: 1;
    transform: scale(1.02);
  }

  100% {
    opacity: 0.5;
    transform: scale(0.98);
  }
}

.history-highlight {
  animation: gentle-pulse 1.5s infinite;
  position: relative;
}

.history-highlight svg {
  fill: rgba(255, 255, 255, 1) !important;
}

.history-highlight::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  background-color: #2E8AF6;
  border-radius: 50%;
  box-shadow: 0 0 8px #2E8AF6;
}

.swap-history-item__icon-wrapper {
  position: relative;
}

.swap-history-item__source-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #161616;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1F1E1E;
}

.swap-history-item__source-badge img {
  width: 12px;
  height: 12px;
  object-fit: contain;
}


.asset_balance_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}


.turbo_badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background: linear-gradient(90deg, #1769E2 0%, #1554BC 100%);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'SF UI Display Semibold';
}

.turbo_icon {
  color: #FFD700;
}


.turbo_balance {
  display: flex;
  align-items: center;
}

.header_icon {
  opacity: 0.6;
  color: #ffffff;
  cursor: pointer;
}

.alert-highlight {
  animation: gentle-pulse 3s infinite;
  position: relative;
}

.alert-highlight::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 6px;
  height: 6px;
  background-color: #2E8AF6;
  border-radius: 50%;
  box-shadow: 0 0 8px #2E8AF6;
}

.spin_action_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.spin_toolbar_action_button {
  color: #fff;
  font-family: 'SF UI Display Semibold';
  font-size: 13px;
  padding: 6px 16px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
  text-align: center;
}

.spin_toolbar_action_button:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-1px);
}

.confirm_send_warning {
  font-family: 'SF UI Display Light';
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 1.5;
  max-width: 280px;
  background: #1F1E1E;
  padding: 12px 16px;
  border-radius: 8px;
  margin: 8px auto 16px;
  width: 100%;
}
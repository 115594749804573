.stars-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #161616;
    min-height: 100vh;
}

.stars-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.stars-icon {
    width: 72px;
    height: 72px;
    fill: #FFD700;
    margin-bottom: 24px;
}

.stars-title {
    font-family: 'SF UI Display Semibold';
    font-size: 22px;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.stars-description {
    font-family: 'SF UI Display Light';
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 1.5;
    max-width: 280px;
    background: #1F1E1E;
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 24px;
}

.stars-content {
    background: #1F1E1E;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stars-balance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.stars-balance {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 8px;
}

.stars-balance svg {
    width: 20px;
    height: 20px;
    fill: #FFD700;
}

.stars-balance-amount {
    font-family: 'SF UI Display Semibold';
    font-size: 32px;
    color: #FFFFFF;
}

.stars-balance-label {
    font-family: 'SF UI Display Light';
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

.stars-button {
    background: #CB3636;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    font-family: 'SF UI Display Semibold';
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 16px;
    transition: background-color 0.2s ease;
}

.stars-button:hover {
    background: #d93d3d;
}

.stars-gift-link {
    color: #3390EC;
    text-decoration: none;
    font-family: 'SF UI Display Light';
    font-size: 14px;
    transition: opacity 0.2s ease;
}

.stars-gift-link:hover {
    opacity: 0.8;
}